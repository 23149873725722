import { NO_PRODUCT_IMAGE, staticMediaStoreBaseURL } from '@/config/common';
import { PLANNER_PORTAL_URL } from '@/services/connections.service';
import { getURLPath } from '@/services/url.service';

export const PAGE_NAME = {
  ACTION_CENTER: {
    label: 'ACTION_CENTER',
    regex: /^\/action-center$/,
    template: '/action-center'
  },
  CREATE_USER_EVENT_UPSERT_HOST_PROFILE: {
    label: 'CREATE_USER_EVENT_UPSERT_HOST_PROFILE',
    regex: /^\/host-events\/create-event/,
    template: '/host-events/create-event'
  },
  EVENT_CART_LIST: {
    label: 'EVENT_CART_LIST',
    regex: /^\/host-events\/.*?\/event-cart-list$/,
    template: '/host-events/:userEventId/event-cart-list'
  },
  EXPIRED_BOOKED_USER_EVENTS: {
    label: 'EXPIRED_BOOKED_USER_EVENTS',
    regex: /^\/user-events\/outstanding-payment/,
    template: '/user-events/outstanding-payment'
  },
  EXPIRED_USER_EVENTS: {
    label: 'EXPIRED_USER_EVENTS',
    regex: /^\/user-events\/expired/,
    template: '/user-events/expired'
  },
  HOST_CREDIT_ENTITY_HOST_LIST: {
    label: 'HOST_CREDIT_ENTITY_HOST_LIST',
    regex: /^\/credit-entity\/.*?\/credit-entity-host-list$/,
    template: '/credit-entity/:hostCreditEntityId/credit-entity-host-list'
  },
  HOST_CREDIT_ENTITY_HOST_PROFILE: {
    label: 'HOST_CREDIT_ENTITY_HOST_PROFILE',
    regex: /^\/credit-entity\/.*?\/profile$/,
    template: '/credit-entity/:hostCreditEntityId/profile'
  },
  HOST_EVENT_PROFILE: {
    label: 'HOST_EVENT_PROFILE',
    regex: /^\/host-events\/.*?\/profile$/,
    template: '/host-events/:userEventId/profile'
  },
  HOST_EVENT_LIST: {
    label: 'HOST_EVENT_LIST',
    regex: /^\/hosts\/.*?\/host-event-list$/,
    template: '/hosts/:userId/host-event-list'
  },
  HOST_PROFILE: {
    label: 'HOST_PROFILE',
    regex: /^\/hosts\/.*?\/profile$/,
    template: '/hosts/:userId/profile'
  },
  METABASE_PLANNER_BOARD: {
    label: 'METABASE_PLANNER_BOARD',
    regex: /^\/mb\/planner-board$/,
    template: '/mb/planner-board'
  },
  NAVIGATION: {
    label: 'NAVIGATION',
    regex: /^\/search\/navigation/,
    template: '/search/navigation'
  },
  NEW_ASSIGNED_USER_EVENTS: {
    label: 'NEW_ASSIGNED_USER_EVENTS',
    regex: /^\/user-events\/new-assigned/,
    template: '/user-events/new-assigned'
  },
  NOT_FOUND: {
    label: 'NOT_FOUND',
    regex: /^\/404\/./,
    template: '/404'
  },
  ORDER_LIST: {
    label: 'ORDER_LIST',
    regex: /^\/orders$/,
    template: '/orders'
  },
  ORDER_VIEW: {
    label: 'ORDER_VIEW',
    regex: /^\/orders\/.*?\/view$/,
    template: '/orders/:orderId/view'
  },
  ORDERED_CART: {
    label: 'ORDERED_CART',
    regex: /^\/carts\/.*?\/order$/,
    template: '/carts/:cartId/order'
  },
  PLANNER_CART: {
    label: 'PLANNER_CART',
    regex: /^\/carts\/.*?\/plan$/,
    template: '/carts/:cartId/plan'
  },
  PRODUCT_DETAIL: {
    label: 'PRODUCT_DETAIL',
    regex: /^\/products\/[\w-]+$/,
    template: '/products/:slug'
  },
  PRODUCT_SEARCH_RESULT: {
    label: 'PRODUCT_SEARCH_RESULT',
    regex: /^\/search\/product\/list/,
    template: '/search/product/list'
  },
  PRODUCT_SEARCH: {
    label: 'PRODUCT_SEARCH',
    regex: /^\/search\/product$/,
    template: '/search/product'
  },
  TENDER_BID_RESPONSE: {
    label: 'TENDER_BID_RESPONSE',
    regex: /^\/tenders\/.*?\/bid-responses$/,
    template: '/tenders/:referenceId/bid-responses'
  },
  WELCOME: {
    label: 'WELCOME',
    regex: /^\/$/,
    template: '/'
  }
};

export const getPageURL = ({
  excludeBaseUrl = true,
  pageName,
  pathParams = {},
  queryParams = {}
}) => {
  const pathname =
    PAGE_NAME[pageName]?.template ?? PAGE_NAME.NOT_FOUND.template;

  const urlSuffix = getURLPath({
    pathname,
    pathParams,
    queryParams
  });

  const urlPrefix = !excludeBaseUrl ? PLANNER_PORTAL_URL : '';
  return `${urlPrefix}${urlSuffix}`;
};

export const noProductImageURL = `${staticMediaStoreBaseURL}${NO_PRODUCT_IMAGE.url}`;

export const apiResponseParser = async ({ listOfAPI = [], setShowToast }) => {
  try {
    const response = await Promise.all(listOfAPI);
    const hasError = response
      .map(({ status }) => status)
      .every(({ val }) => val);
    if (hasError) {
      const errorMessages = response
        .filter(({ status }) => status === false)
        .map(({ message }) => message || 'Unknown error');
      setShowToast({
        message: `Error while fetching API Info: ${errorMessages.join(', ')}`,
        show: true,
        successToast: false
      });
      return false;
    }
    return response.map(({ entity }) => entity);
  } catch (error) {
    setShowToast({
      message: error.message || 'Error while fetching API Info',
      show: true,
      successToast: false
    });
    return false;
  }
};
