export const BID_SUBMISSION_STATUS = {
  PARTIALLY_SUBMITTED: {
    label: 'In-Progress',
    value: 'PARTIALLY_SUBMITTED'
  },
  PENDING: { label: 'New', value: 'PENDING' },
  SUBMITTED: { label: 'Submitted', value: 'SUBMITTED' }
};

export const BID_ITEM_STATUS = {
  ACCEPTED: { className: 'bg-green', label: 'Available', value: 'ACCEPTED' },
  PENDING: { className: 'bg-dim-gray', label: 'Pending', value: 'PENDING' },
  REJECTED: {
    className: 'bg-error-red',
    label: 'Not Available',
    value: 'REJECTED'
  }
};

export const HOST_INTERACTION_STATUS = {
  INQUIRED: {
    className: 'in-progress',
    label: 'Inquired',
    sortOrder: 1,
    value: 'INQUIRED'
  },
  NEW: { className: 'new', label: 'New', sortOrder: 0, value: 'NEW' },
  REPEAT: {
    className: 'submitted',
    label: 'Repeat',
    sortOrder: 2,
    value: 'REPEAT'
  }
};

export const CART_ITEM_STATUS = {
  ACCEPTED: { value: 'ACCEPTED' },
  DECLINED: { value: 'DECLINED' },
  ORDERED: { value: 'ORDERED' },
  REQUESTED: { value: 'REQUESTED' },
  REQUEST_PENDING: { value: 'REQUEST_PENDING' }
};

export const ZENDESK_PRIORITY = {
  HIGH: {
    className: 'submitted rounded-lg border border-green-base',
    icon: 'green-status-icon.svg',
    value: 'High'
  },
  LOW: {
    className: 'expired rounded-lg border border-error-red',
    icon: 'red-status-icon.svg',
    value: 'Low'
  },
  NORMAL: {
    className: 'in-progress rounded-lg border border-warning-dark',
    icon: 'yellow-status-icon.svg',
    value: 'Normal'
  },
  URGENT: {
    className: 'new rounded-lg border border-info-base',
    icon: 'blue-status-icon.svg',
    value: 'Urgent'
  }
};

export const CART_STATUS = {
  ABANDON: { value: 'Abandon' },
  APPROVE: { value: 'Approve' },
  COLLABORATE: {
    className: 'bg-greenlight text-green',
    label: 'Collaborate',
    value: 'Collaborate'
  },
  DISCARD: { value: 'Discard' },
  DRAFT: {
    className: 'bg-gray text-dim-gray',
    label: 'Draft',
    value: 'Draft'
  },
  ORDER: {
    className: 'bg-light-red text-brand',
    label: 'Order',
    value: 'Order'
  },
  QUOTE: { value: 'Quote' },
  REJECT: { value: 'Reject' },
  REVISE: { value: 'Revise' }
};

export const ORDER_STATUS = {
  CANCELLED: {
    class: 'expired',
    label: 'Cancelled',
    sortOrder: 8,
    value: 'CANCELLED'
  },
  COMPLETED_NOT_SETTLED: {
    class: 'in-progress',
    label: 'Completed: Not Settled',
    sortOrder: 5,
    value: 'COMPLETED_NOT_SETTLED'
  },
  COMPLETED_SETTLED: {
    class: 'submitted',
    label: 'Completed: Settled',
    sortOrder: 6,
    value: 'COMPLETED_SETTLED'
  },
  DISPUTED: {
    class: 'expired',
    label: 'Disputed',
    sortOrder: 10,
    value: 'DISPUTED'
  },
  ORDER_CONFIRMED: {
    class: 'submitted',
    label: 'Order Confirmed',
    sortOrder: 2,
    value: 'ORDER_CONFIRMED'
  },
  ORDER_INITIATED: {
    class: 'bg-neutral text-dim-gray',
    label: 'Order Initiated',
    sortOrder: 0,
    value: 'ORDER_INITIATED'
  },
  ORDER_PLACED: {
    class: 'new',
    label: 'Order Placed',
    sortOrder: 1,
    value: 'ORDER_PLACED'
  },
  PARTNER_BOOKED: {
    class: 'submitted',
    label: 'Partner Booked',
    sortOrder: 3,
    value: 'PARTNER_BOOKED'
  },
  POSTPONED: {
    class: 'in-progress',
    label: 'Postponed',
    sortOrder: 9,
    value: 'POSTPONED'
  },
  READY_FOR_EXECUTION: {
    class: 'submitted',
    label: 'Ready for Execution',
    sortOrder: 4,
    value: 'READY_FOR_EXECUTION'
  },
  SETTLED_AND_CLOSED: {
    class: 'submitted',
    label: 'Settled and Closed',
    sortOrder: 7,
    value: 'SETTLED_AND_CLOSED'
  },
  SUSPENDED: {
    class: 'expired',
    label: 'Suspended',
    sortOrder: 11,
    value: 'SUSPENDED'
  }
};

export const LINE_ITEM_SOURCE = {
  CART: {
    value: 'CART'
  },
  ORDER: {
    value: 'ORDER'
  }
};

export const DEVICE_SURFACE = {
  MOBILE: 'mobile',
  WEB: 'web'
};

export const HAFLA_BANK_DETAILS = [
  { label: 'Account No', value: '019100732211' },
  { label: 'Bank', value: 'Mashreq Bank' },
  { label: 'IBAN', value: 'AE460330000019100732211' },
  { label: 'Name', value: 'Evinops Limited' },
  { label: 'Swift Code', value: 'BOMLAEAD' }
];

export const HAFLA_REGISTRATION_DETAILS = {
  companyName: 'Evinops Limited (Hafla)',
  formattedAddress: 'Abu Dhabi, UAE PO Box: 46617',
  trnNumber: '100475504500003'
};

export const IMAGEKIT_DAM_ROOT_DIRECTORY = `/API/${process.env.NEXT_PUBLIC_APP_ENV}`;

export const NAMED_QUERY_PARAMS = {
  ANYBODY_CAN_PAY_RAW: 'anybody-can-pay',
  ORDER_ID: 'order-id',
  PAYMENT_FLOW_SOURCE: 'payment-flow-source',
  PAYMENT_ID: 'payment-id',
  PAYMENT_RECEIPT_STATUS: 'payment-receipt-status',
  PAYMENT_SOURCE_TYPE: 'reference-type',
  REFERENCE_ID: 'reference-id',
  TRANSACTION_AMOUNT: 'transaction-amount',
  USER_ID: 'user-id'
};

export const MEDIA_SOURCE = {
  AWS_S3: 'AWS_S3',
  CODE_REPO: 'CODE_REPO',
  IMAGE_KIT: 'IMAGE_KIT',
  LOCALHOST: 'LOCALHOST'
};

export const mediaStoreBaseURL =
  process.env.STORYBOOK_PUBLIC_MEDIA_STORE_URL ||
  process.env.NEXT_PUBLIC_MEDIA_STORE_URL ||
  '';

export const PRODUCT_STATUS = {
  DRAFT: 'Draft',
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished'
};

export const PAYMENT_FLOW_SOURCE = {
  ANYBODY_CART: 'ANYBODY_CART',
  ANYBODY_ORDER: 'ANYBODY_ORDER',
  HOST_CART_DEFAULT: 'HOST_CART_DEFAULT',
  HOST_CART_NON_DEFAULT: 'HOST_CART_NON_DEFAULT',
  HOST_ORDER: 'HOST_ORDER',
  PLANNER_CART: 'PLANNER_CART',
  PLANNER_ORDER: 'PLANNER_ORDER'
};

export const PAYMENT_SOURCE_TYPE = {
  ORDER: 'order',
  CART: 'cart'
};

export const PRODUCT_TYPE = {
  QUOTE: 'Quote',
  TICKET: 'Ticket',
  CART: 'Cart'
};

export const INVOICE_QUOTE_PDF_TYPE = {
  INVOICE: 'INVOICE',
  QUOTE: 'QUOTE'
};

export const PDF_TITLE = {
  PROFORMA_INVOICE: 'PROFORMA INVOICE',
  QUOTATION: 'QUOTATION',
  TAX_INVOICE: 'TAX INVOICE'
};

export const UNAVAILABLE_CART_ITEM_ALERT =
  'We apologize! One or more products in your cart are currently unavailable. Please remove those products from the cart to checkout.';

export const STATIC_MEDIA_STORE_PATH_STUB = '/static_images/planner';

export const staticMediaStoreBaseURL = `${mediaStoreBaseURL}${STATIC_MEDIA_STORE_PATH_STUB}`;
export const haflaPublicSupportMobileNumber = '+971564154990';

export const VAT_PERCENTAGE = 5;

export const ZENDESK_CONFIG = {
  agentTicketViewURL: 'https://hafla4229.zendesk.com/agent/tickets/'
};

export const CART_LIST_TYPE = {
  EVENT: 'EventCartList',
  ORDER: 'OrderedCartList',
  ARCHIVE: 'ArchivedCartList'
};

export const NO_PRODUCT_IMAGE = {
  imageTitle: '',
  sortOrder: 1,
  source: MEDIA_SOURCE.AWS_S3,
  url: '/noProductImage.png'
};

export const TAG_OPTIONS_SELECTION_MODE = {
  MULTIPLE: 'MULTIPLE',
  SINGLE: 'SINGLE'
};

export const VERIFICATION_STATUSES = {
  FAILED: 'FAILED',
  UNCHECKED: 'UNCHECKED',
  VERIFIED: 'VERIFIED'
};

export const ACTION_MODAL_SOURCE = {
  ADD_ITEM_TO_CART: 'ADD_ITEM_TO_CART',
  EXPORT_CART: 'EXPORT_CART',
  IMPORT_CART: 'IMPORT_CART'
};

export const isProduction = process.env.NEXT_PUBLIC_APP_ENV === 'production';

export const USER_EVENT_STAGE = {
  CART_COMPLETED: {
    class: 'new',
    label: 'Cart Completed',
    sortOrder: 5,
    value: 'CART_COMPLETED'
  },
  EPIC_ENHANCED: {
    class: 'in-progress',
    label: 'EPIC Enhanced',
    sortOrder: 3,
    value: 'EPIC_ENHANCED'
  },
  EPIC_GENERATED: {
    class: 'bg-neutral text-dim-gray',
    label: 'EPIC Generated',
    sortOrder: 4,
    value: 'EPIC_GENERATED'
  },
  EVENT_ACCEPTED: {
    class: 'in-progress',
    label: 'Event Accepted',
    sortOrder: 1,
    value: 'EVENT_ACCEPTED'
  },
  EVENT_ASSIGNED: {
    class: 'bg-neutral text-dim-gray',
    label: 'Event Assigned',
    sortOrder: 0,
    value: 'EVENT_ASSIGNED'
  },
  EVENT_CREATED: {
    class: 'bg-neutral text-dim-gray',
    label: 'Event Created',
    sortOrder: 2,
    value: 'EVENT_CREATED'
  },
  NOT_CONVERTED: {
    class: 'expired',
    label: 'Not Converted',
    sortOrder: 9,
    value: 'NOT_CONVERTED'
  },
  ORDER_CONFIRMED: {
    class: 'submitted',
    label: 'Order Confirmed',
    sortOrder: 7,
    value: 'ORDER_CONFIRMED'
  },
  ORDER_PLACED: {
    class: 'submitted',
    label: 'Order Placed',
    sortOrder: 6,
    value: 'ORDER_PLACED'
  },
  SOLUTION_DESIGN: {
    class: 'new',
    label: 'Solution Design',
    sortOrder: 8,
    value: 'SOLUTION_DESIGN'
  }
};

export const ALLOCATION_STATUS = {
  NOT_READY_FOR_ALLOCATION: {
    className: 'expired',
    label: 'Not Ready for Allocation',
    value: 'NOT_READY_FOR_ALLOCATION'
  },
  READY_FOR_ALLOCATION: {
    className: 'expired',
    label: 'Ready for Allocation',
    value: 'READY_FOR_ALLOCATION'
  },
  ACKNOWLEDGEMENT_PENDING: {
    className: 'expired',
    label: 'Acknowledgment Pending',
    value: 'ACKNOWLEDGEMENT_PENDING'
  },
  EVENT_ACCEPTED: {
    className: 'submitted',
    label: 'Event Accepted',
    value: 'EVENT_ACCEPTED'
  },
  REASSIGN_REQUESTED: {
    className: 'expired',
    label: 'Reassign Requested',
    value: 'REASSIGN_REQUESTED'
  }
};

export const MANDATORY_FIELDS_FOR_EPIC_ENHANCED = {
  CONTACT_METHOD_ID: 'contactMethodId',
  EVENT_DURATION_IN_DAYS: 'eventDurationInDays',
  EVENT_SITE_TYPE_ID: 'eventSiteTypeId',
  EVENT_START_TIME: 'eventStartTime',
  EVENT_TITLE: 'eventTitle',
  FORMATTED_ADDRESS: 'formattedAddress',
  OPPORTUNITY_VALUE: 'opportunityValue',
  THEME: 'theme',
  ZENDESK_TICKET_ID: 'zendeskTicketId'
};

export const MANDATORY_FIELDS_FOR_EPIC_GENERATED = {
  EVENT_DATE: 'eventDate',
  EVENT_TYPE: 'checkoutEventId',
  EVENT_VERTICALS: 'eventVerticals',
  EXPECTED_GUEST_COUNT: 'expectedGuestCount',
  REQUESTER_NAME: 'eventContactName',
  REQUESTER_TYPE: 'customerName',
  TRIAGE_SUMMARY: 'triageSummary',
  ZENDESK_TICKET_ID: 'zendeskTicketId'
};

export const HOST_NAME_VALIDATION_CONFIG = {
  nameRegex: /^[A-Za-z][A-Za-z0-9_ ,.'-]{2,63}$/,
  nameInputError:
    'Name must be at least 3 characters. Only letters, numbers, spaces, _ and - allowed.'
};

export const HOST_CREDIT_ENTITY_NAME_VALIDATION_CONFIG = {
  nameRegex: /^.{2,}$/,
  nameInputError: 'Name must be at least 2 characters.'
};

export const NAVIGATION_ENTITY_TYPE = {
  CART: 'CART',
  HOST_CREDIT_ENTITY: 'HOST_CREDIT_ENTITY',
  HOST: 'HOST',
  ORDER: 'ORDER',
  USER_EVENT: 'USER_EVENT'
};

export const EVENT_REQUEST_TYPE = {
  END_TO_END: 'End-to-End',
  PRODUCT_AND_SERVICE_ONLY: 'Product/Service Only'
};

export const NOTIFICATION_TYPE = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning'
};

export const DEFAULT_TOAST_CONFIG = {
  message: null,
  show: false,
  successToast: false
};

export const HOST_TYPES_WITH_ADDITIONAL_REQUIREMENTS = [
  'Corporate Generic',
  'Event Planning Company',
  'Government Organization',
  'Large Corporate',
  'Large Event Agency'
];

export const TOOLTIP_DELAY_TIME = 500;

export const TOOLTIP_HIDE_TIME = 100;

export const USER_EVENT_ACTION = {
  ACCEPT: { label: 'Accept', value: ALLOCATION_STATUS.EVENT_ACCEPTED.value },
  REASSIGN: {
    label: 'Reassign',
    value: ALLOCATION_STATUS.REASSIGN_REQUESTED.value
  }
};

export const USER_EVENT_SOURCE = {
  PLANNER_PORTAL_CREATE_USER_EVENT: 'PLANNER_PORTAL_CREATE_USER_EVENT',
  PLANNER_PORTAL_UPDATE_USER_EVENT: 'PLANNER_PORTAL_UPDATE_USER_EVENT'
};

export const DEFAULT_LOCATION_FOR_EVENT = {
  cityId: 'dubai',
  formattedAddress: 'Dubai - United Arab Emirates',
  latitude: 25.2048493,
  locationSelected: true,
  longitude: 55.2707828
};

export const HOST_TYPE = {
  'Corporate Generic': {
    label: 'Corporate Generic',
    sortOrder: 2,
    value: 'Corporate Generic'
  },
  'Event Planner': {
    label: 'Event Planner',
    sortOrder: 6,
    value: 'Event Planner'
  },
  'Event Planning Company': {
    label: 'Event Planning Company',
    sortOrder: 3,
    value: 'Event Planning Company'
  },
  'Government Organization': {
    label: 'Government Organization',
    sortOrder: 4,
    value: 'Government Organization'
  },
  Individual: {
    label: 'Individual',
    sortOrder: 0,
    value: 'Individual'
  },
  'Large Corporate': {
    label: 'Large Corporate',
    sortOrder: 5,
    value: 'Large Corporate'
  },
  'Large Event Agency': {
    label: 'Large Event Agency',
    sortOrder: 7,
    value: 'Large Event Agency'
  },
  'VIP/Influencer': {
    label: 'VIP/Influencer',
    sortOrder: 1,
    value: 'VIP/Influencer'
  }
};
